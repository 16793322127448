<template>
  <div class="page page-index is-grants" :class="{ 'has-breadcrumbs': displayBreadcrumbs }">
    <pt-blob v-bind="getBlobBindings(blobConfig)" class="page-index__blob" />
    <div v-if="displayBreadcrumbs" class="page__breadrumbs container">
      <pt-breadcrumbs />
    </div>
    <div class="page-index__header container">
      <pt-section-header :title="currentPage.title" title-tag="h1" title-size="1" :description="currentPage.description" class="has-marg-bottom-l" />
    </div>
    <div class="container">
      <grants-search-form />
      <grants-search-results />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import PtBreadcrumbs from '~/components/global/elements/PtBreadcrumbs.vue';
import PtBlob from '~/components/global/elements/PtBlob.vue';
import PtSectionHeader from '~/components/global/elements/PtSectionHeader.vue';
import { usePages } from '~/composables/pages';
import { useBlob } from '~/composables/blob';
import GrantsSearchForm from '~/components/global/components/grants/GrantsSearchForm.vue';
import GrantsSearchResults from '~/components/global/components/grants/GrantsSearchResults.vue';

const { currentPage, displayBreadcrumbs } = usePages();
const { getBlobBindings } = useBlob();

const blobConfig = reactive({
  blob_enabled: true,
  blob_shape: 'circle',
  blob_side: 'center',
  blob_colour: 'green',
  blob_position: 'higher',
  blob_rotation: 120,
});
</script>
