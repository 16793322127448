<template>
  <div class="grants-search-results has-marg-top-l has-marg-bottom-l">
    <client-only>
      <transition name="fade" mode="out-in">
        <div v-if="lastResult" key="results">
          <transition-group v-if="hits && hits.length > 0" name="fade" tag="div" class="columns is-multiline is-variable is-2">
            <div v-for="(hit, index) in hits" :key="`${index}`" class="grants-search-results__col column is-6">
              <grants-search-result-tile :hit="hit" />
            </div>
          </transition-group>
          <div v-if="!isLoading && (!hits || !hits.length)" key="no-results" class="grants-search-results__no-results">
            No results have been found<span v-if="searchParams.query">
              for <strong>{{ searchParams.query }}</strong></span
            >
          </div>
          <transition name="fade">
            <div v-if="canLoadMore" class="grants-search-results__load-more has-marg-top-l">
              <button type="button" class="button is-primary is-outlined is-wider" :disabled="isLoading" @click="loadMore">
                <span>Load more</span>
              </button>
            </div>
          </transition>
        </div>
        <div v-else-if="!lastResult && isLoading" key="loading" class="grants-search-results__loading">Searching...</div>
      </transition>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useGrantsSearchStore } from '@/stores/grants-search';
import GrantsSearchResultTile from '~/components/global/components/grants/GrantsSearchResultTile.vue';

const store = useGrantsSearchStore();

const searchParams = computed(() => store.searchParams);
const isLoading = computed(() => store.isLoading);
const hits = computed(() => store.hits);
const lastResult = computed(() => store.lastResult);
const canLoadMore = computed(() => store.canLoadMore);

const loadMore = () => {
  store.search({ ...searchParams.value, page: searchParams.value.page + 1 }, true, true, false);
};
</script>
