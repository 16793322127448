<template>
  <div class="grants-search-form has-marg-top-l">
    <form @submit.prevent="handleSubmit">
      <div class="grants-search-form__type-buttons">
        <button
          type="button"
          class="grant-search-form__type-button button-tab"
          :class="{ 'is-active': isTypeIndividual }"
          aria-label="Toggle grants For Individuals"
          @click="updateType('individual')"
        >
          For Individuals
        </button>
        <button
          type="button"
          class="grant-search-form__type-button button-tab"
          :class="{ 'is-active': isTypeOrganisation }"
          aria-label="Toggle grants For Organisations"
          @click="updateType('organisation')"
        >
          For Organisations
        </button>
      </div>

      <div class="grants-search-form__content">
        <div class="grants-search-form__fields">
          <div class="grants-search-form__fields-row">
            <div class="grants-search-form__field">
              <label for="grants-keywords-input" class="grants-search-form__field-label"> Keywords </label>
              <input
                id="grants-keywords-input"
                ref="searchInput"
                :value="store.searchParams.query"
                type="search"
                class="grants-search-form__field-input hide-clear"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                aria-label="Search keywords"
                @input="updateQuery"
              />
            </div>
            <div class="grants-search-form__field">
              <label for="grants-region-select" class="grants-search-form__field-label"> Region </label>
              <pt-select
                label="title"
                input-id="grants-region-select"
                uid="region"
                :options="regionOptions"
                :model-value="selectedRegion"
                :reduce="(i) => i.slug"
                @update:model-value="updateRegion"
              />
            </div>
            <div class="grants-search-form__field">
              <label for="grants-sector-select" class="grants-search-form__field-label"> Sector </label>
              <pt-select
                label="title"
                input-id="grants-sector-select"
                uid="sector"
                :options="sectorOptions"
                :model-value="selectedSector"
                :reduce="(i) => i.slug"
                @update:model-value="updateSector"
              />
            </div>
          </div>
          <div class="grants-search-form__fields-row">
            <div class="grants-search-form__field">
              <button
                type="button"
                class="grants-search-form__applications-open-toggle"
                :class="{ 'is-active': isOpenOnly }"
                aria-label="Toggle grants with applications open now"
                @click="toggleOpenOnly"
              >
                <transition name="fade">
                  <pt-icon-checkbox-on v-if="isOpenOnly" class="grants-search-form__applications-open-toggle-icon" />
                  <pt-icon-checkbox-off v-else class="grants-search-form__applications-open-toggle-icon" />
                </transition>
                Applications open now
              </button>
            </div>
          </div>
        </div>
        <div class="grants-search-form__submit">
          <button type="submit" class="grants-search-form__submit-button button is-white" aria-label="Search">Search</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { initialSearchParams, useGrantsSearchStore } from '@/stores/grants-search';
import { GRANTS_TYPE_INDIVIDUAL, GRANTS_TYPE_ORGANISATION, GRANTS_INPUT_DEBOUNCE_MS } from '@/constants/grants';
import PtIconCheckboxOn from '~/components/global/icons/PtIconCheckboxOn.vue';
import PtIconCheckboxOff from '~/components/global/icons/PtIconCheckboxOff.vue';
import { debounce } from '~/utils/debounce';
import PtSelect from '~/components/global/elements/PtSelect.vue';

const store = useGrantsSearchStore();
const route = useRoute();

const searchInput = ref(null);
const cancelDebounce = ref(false);

const isOpenOnly = computed(() => store.searchParams.openOnly);
const isTypeIndividual = computed(() => store.searchParams.type === GRANTS_TYPE_INDIVIDUAL);
const isTypeOrganisation = computed(() => store.searchParams.type === GRANTS_TYPE_ORGANISATION);
const selectedRegion = computed(() => store.searchParams.region || null);
const regionOptions = computed(() => [{ title: 'All', slug: null }].concat(store.regions));
const selectedSector = computed(() => store.searchParams.sector || null);
const sectorOptions = computed(() => [{ title: 'All', slug: null }].concat(store.sectors));

const search = (searchParams, pushHistory = true) => {
  store.search({ ...store.searchParams, ...searchParams }, false, pushHistory, true);
};

const handleHistoryPopState = () => {
  search({ ...initialSearchParams, ...route.query, openOnly: route.query.openOnly === 'true' ? true : undefined }, false);
};

const updateType = (type: string) => {
  if (store.searchParams.type === type) return;
  search({ type });
};

const toggleOpenOnly = () => {
  search({ openOnly: !store.searchParams.openOnly ? true : undefined });
};

const updateQuery = debounce(() => {
  if (cancelDebounce.value) return;
  search({ query: searchInput.value.value || undefined });
}, GRANTS_INPUT_DEBOUNCE_MS);

const updateRegion = (region: string) => {
  search({ region: region || undefined });
};

const updateSector = (sector: string) => {
  search({ sector: sector || undefined });
};

const handleSubmit = () => {
  cancelDebounce.value = true;
  search({ query: searchInput.value.value || undefined });
  setTimeout(() => {
    cancelDebounce.value = false;
  }, GRANTS_INPUT_DEBOUNCE_MS);
};

onMounted(() => {
  store.reset();
  search({ ...route.query, openOnly: route.query.openOnly === 'true' ? true : undefined }, true);
  window.addEventListener('popstate', handleHistoryPopState);
});

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handleHistoryPopState);
});
</script>
